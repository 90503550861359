import { Box, Link, Typography } from "@material-ui/core";
import { withRouter } from "react-router";
import React, { ReactNode } from "react";

const CONTACT_EMAIL = "synonimy@kwezal.com";

const Paragraph = (props: { readonly children: ReactNode }) => (
  <Box mb={1}>
    <Typography variant="h6" align="justify">
      {props.children}
    </Typography>
  </Box>
);

const Header = (props: { readonly children: ReactNode }) => (
  <Box mt={3}>
    <Typography variant="h4" component="h2">
      {props.children}
    </Typography>
  </Box>
);

function AboutDictionaryComponent() {
  return (
    <>
      <Typography variant="h4" component="h1">
        Słownik synonimów
      </Typography>
      <Paragraph>
        Niniejszy zbiór wyrazów bliskoznacznych składa się z ponad czterdziestu
        tysięcy słów i wyrażeń. Ponadto, hasła są pogrupowane w warianty
        znaczeniowe i kontekstowe. Do odnajdywania synonimów służą:{" "}
        <strong>wygodna wyszukiwarka z funkcją autouzupełniania</strong> na
        podstawie bazy wyrazów bliskoznacznych, oraz lista haseł uporządkowana
        alfabetycznie.
      </Paragraph>
      <Paragraph>
        Naszym celem było dostarczenie rozwiązania szybkiego, niezawodnego i -
        przede wszystkim - pomocnego wszystkim szukającym sposobu na{" "}
        <strong>urozmaicenie swoich wypowiedzi</strong>. Aplikacja może służyć
        zarówno miłośnikom pięknej i barwnej polszczyzny, jak i osobom
        pracującym na co dzień z językiem polskim, a więc m.in. nauczycielom,
        pisarzom, poetom, tłumaczom, a także pracownikom branż kreatywnej i
        reklamowej.
      </Paragraph>
      <Header>Bez reklam</Header>
      <Paragraph>
        Kwezal Synonimy jest serwisem{" "}
        <strong>darmowym i wolnym od reklam</strong>, a co więcej,{" "}
        <strong>zawsze taki pozostanie</strong>. Zależało nam na stworzeniu
        ogólnodostępnego słownika synonimów, abyśmy wszyscy (w tym Kwezal 😉)
        mogli bez przeszkód ożywić nasz język z pomocą intuicyjnego narzędzia.
      </Paragraph>
      <Header>Kontakt</Header>
      <Paragraph>
        Jeżeli masz jakieś pytania, uwagi, bądź propozycje, możesz skontaktować
        się z nami poprzez skrzynkę e-mail pod adresem:{" "}
        <Link href={`mailto:${CONTACT_EMAIL}`}>
          {CONTACT_EMAIL}
        </Link>
        .
      </Paragraph>
    </>
  );
}

export default withRouter(AboutDictionaryComponent);
