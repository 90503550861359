import { Slide, useScrollTrigger } from "@material-ui/core";
import { ReactElement } from "react";

interface Props {
  children: ReactElement;
}

export default function HideOnScroll(props: Props) {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {props.children}
    </Slide>
  );
}
