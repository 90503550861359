import { Typography } from "@material-ui/core";
import { ReactNode } from "react";

interface Props {
  readonly title: string;
  readonly message: ReactNode;
}

export default function SynonymErrorComponent(props: Props) {
  return (
    <>
      <Typography variant="h5" component="h2" color="error" className="bold">
        {props.title}
      </Typography>
      <Typography>{props.message}</Typography>
    </>
  );
}
