import { Grid, List, ListItem, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import AppRoute from "../../config/AppRoute";

const MAX_LIST_LENGTH = 25;

interface FetchResult {
  readonly letter: string;
  readonly page: number;
  readonly words: string[];
}

interface Props {
  readonly letter: string;
  readonly page: number;
}

export default function DictionaryPage(props: Props) {
  const [fetchResult, setFetchResult] = useState<FetchResult | null>(null);

  const isFetchResultReady = () =>
    fetchResult !== null &&
    fetchResult.letter === props.letter &&
    fetchResult.page === props.page;

  useEffect(() => {
    if (!isFetchResultReady()) {
      fetchPage(props.letter, props.page)
        .then((result) => setFetchResult(result))
        .catch(() => setFetchResult(null));
    }
  });

  return isFetchResultReady() ? (
    <Grid container spacing={3}>
      {buildGridItems(fetchResult!.words, MAX_LIST_LENGTH)}
    </Grid>
  ) : null;
}

function buildGridItems(words: string[], maxListLength: number) {
  const wordColumns = [];
  for (let i = 0; i < words.length; i += maxListLength) {
    wordColumns.push(words.slice(i, i + maxListLength));
  }

  const items: JSX.Element[] = [];
  wordColumns.forEach((column, index) =>
    items.push(
      <Grid key={index} item xs>
        <List dense>
          {column.map((word, index) => (
            <ListItem key={index}>
              <Typography
                color="textPrimary"
                component={RouterLink}
                to={`${AppRoute.synonym}/${word}`}
                className="word-link"
              >
                {word}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Grid>
    )
  );

  return items;
}

async function fetchPage(letter: string, page: number): Promise<FetchResult> {
  const response = await fetch(`/res/synonyms/pages/${letter}/${page}.json`);
  const json = await response.json();
  return { letter: letter, page: page, words: json };
}
