import SynonymVariantsComponent from "./SynonymVariantsComponent";
import SynonymErrorComponent from "./SynonymErrorComponent";
import { RouteComponentProps, withRouter } from "react-router";
import WordSearchBar from "../main/WordSearchBar";
import { Box } from "@material-ui/core";
import React from "react";

interface PropsParams {
  readonly query: string;
}

interface Props extends RouteComponentProps<PropsParams> {}

function SynonymPage(props: Props) {
  const query = props.match.params.query;
  const parseResult = parsePathname(query);
  const word = parseResult.word;

  return parseResult.error ? (
    <SynonymErrorComponent
      title="Błędne zapytanie"
      message="Szukane wyrażenie zawiera nieprawidłowe znaki."
    />
  ) : (
    <>
      <Box mb={2}>
        <WordSearchBar />
      </Box>
      <SynonymVariantsComponent word={word} />
    </>
  );
}

export default withRouter(SynonymPage);

const VALIDATION_REGEX = /^[a-z\u00c0-\u00d6\u00d8-\u00f6\u00f8-\u017f 0-9]+$/;

function parsePathname(pathname: string) {
  const query = pathname.toLowerCase();
  const isValid = query.match(VALIDATION_REGEX);
  return { word: query, error: !isValid };
}
