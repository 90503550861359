import React from "react";
import "./App.css";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import MainPage from "./ui/main/MainPage";
import NotFoundPage from "./ui/error/NotFoundPage";
import appTheme from "./ui/theme/AppTheme";
import { CssBaseline } from "@material-ui/core";
import AppRoute from "./config/AppRoute";
import appHistory from "./navigation/AppHistory";

export default function App() {
  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <Router history={appHistory}>
        <Switch>
          <Route
            key={AppRoute.home}
            exact
            path={AppRoute.home}
            component={MainPage}
          />
          <Route
            key={AppRoute.synonym}
            path={AppRoute.synonym}
            component={MainPage}
          />
          <Route
            key={AppRoute.aboutDictionary}
            path={AppRoute.aboutDictionary}
            component={MainPage}
          />
          <Route
            key={AppRoute.dictionaryEntries}
            path={AppRoute.dictionaryEntries}
            component={MainPage}
          />
          <Route exact path={AppRoute.notFound} component={NotFoundPage} />
          <Redirect to={AppRoute.notFound} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}
