import React, { useState } from "react";
import { debounce } from "@material-ui/core";
import AppRoute from "../../config/AppRoute";
import appHistory from "../../navigation/AppHistory";

const UPDATE_OPTIONS_INTERVAL = 300;

export default function useWordSearchBarController() {
  const [options, setOptions] = useState<string[]>([]);
  const [searchText, setSearchText] = useState("");
  const [currentQuery, setCurrentQuery] = useState("");

  let currentSearchText = searchText;

  const search = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (currentSearchText !== "") {
      appHistory.push(`${AppRoute.synonym}/${currentSearchText}`);
    }
  };

  const lazyUpdate = debounce(() => {
    setSearchText(currentSearchText);
    if (currentSearchText.length >= 2) {
      const query = normalizeQuery(currentSearchText.substr(0, 2));
      if (query !== currentQuery) {
        setCurrentQuery(query);
        getOptionsForQuery(query).then((options) => setOptions(options));
      }
    }
  }, UPDATE_OPTIONS_INTERVAL);

  const setSearchTextAndUpdateOptions = (value: string) => {
    currentSearchText = value;
    lazyUpdate();
  };

  return {
    options: options,
    search: search,
    setSearchTextAndUpdateOptions: setSearchTextAndUpdateOptions,
  };
}

function normalizeQuery(query: string) {
  return query
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace("ł", "l");
}

async function getOptionsForQuery(query: string): Promise<string[]> {
  const response = await fetch(`/res/synonyms/ls?q=${query}`);
  return await response.json();
}