import { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import SynonymErrorComponent from "./SynonymErrorComponent";
import SynonymVariant from "./SynonymVariant";
import SynonymVariantComponent from "./SynonymVariantComponent";

interface FetchResult {
  readonly word: string;
  readonly variants?: SynonymVariant[];
  readonly error?: boolean;
}

interface Props {
  readonly word: string;
}

export default function SynonymVariantsComponent(props: Props) {
  const [fetchResult, setFetchResult] = useState<FetchResult | null>(null);

  const isFetchResultReady = () =>
    fetchResult !== null && fetchResult.word === props.word;

  useEffect(() => {
    document.title = `Wyrazy bliskoznaczne słowa ${props.word} | Kwezal Synonimy`;

    if (!isFetchResultReady()) {
      fetchSynonymVariants(props.word)
        .then((result) => setFetchResult(result))
        .catch(() => setFetchResult({ word: props.word, error: true }));
    }
  });

  return isFetchResultReady() ? (
    fetchResult!.error ? (
      <SynonymErrorComponent
        title="Nie znaleziono"
        message={
          <span>
            Brak synonimu do wyrażenia <q>{props.word}</q>.
          </span>
        }
      />
    ) : (
      <>
        <Typography variant="h5" component="h2" className="bold">
          Synonimy słowa <q>{props.word}</q>
        </Typography>
        {fetchResult!.variants!.map((variant, index) => (
          <Box key={index} my={3}>
            <SynonymVariantComponent variant={variant} />
          </Box>
        ))}
      </>
    )
  ) : (
    <></>
  );
}

async function fetchSynonymVariants(word: string): Promise<FetchResult> {
  const response = await fetch(`/res/synonyms/${word.charAt(0)}/${word}.json`);
  const json = await response.json();
  return { word: word, variants: json };
}
