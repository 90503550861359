import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button, Grid, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import useWordSearchBarController from "./WordSearchBarController";

export default function WordSearchBar() {
  const controller = useWordSearchBarController();

  return (
    <form onSubmit={controller.search}>
      <Grid container spacing={1}>
        <Grid item xs>
          <Autocomplete
            freeSolo
            onChange={(event, newValue) =>
              newValue != null &&
              controller.setSearchTextAndUpdateOptions(newValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                color="secondary"
                variant="outlined"
                label="Szukaj synonimu"
                onChange={(event) =>
                  controller.setSearchTextAndUpdateOptions(
                    (event.target as any).value
                  )
                }
              />
            )}
            options={controller.options}
          />
        </Grid>
        <Grid item>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            style={{ height: "100%" }}
          >
            <SearchIcon color="action" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
