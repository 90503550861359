import { Pagination } from "@material-ui/lab";
import AppRoute from "../../config/AppRoute";
import appHistory from "../../navigation/AppHistory";

interface Props {
  readonly letter: string;
  readonly page: number;
  readonly pageSize: number;
}

export default function DictionaryPagination(props: Props) {
  const navigateToPage = (page: number) => {
    appHistory.push(`${AppRoute.dictionaryEntries}/${props.letter}/${page}`);
  };

  return (
    <Pagination
      count={props.pageSize}
      page={props.page}
      siblingCount={2}
      color="primary"
      size="small"
      onChange={(e, page) => navigateToPage(page)}
    />
  );
}
