import React from "react";
import { Avatar, Box, Grid, Link, Paper, Typography } from "@material-ui/core";
import Dimension from "../res/Dimension";
import kwezalLogo from "../../res/graphics/kwezal-logo.png";

export default function MainFooter() {
  return (
    <Paper component="footer">
      <Box display={"flex"} justifyContent={"center"}>
        <Box m={2} maxWidth={Dimension.CONTENT_CONTAINER_MAX_WIDTH} flex={1}>
          <Grid container alignItems="center">
            <Grid item>
              <Box mr={1}>
                <Avatar src={kwezalLogo} alt="Logo Kwezala" />
              </Box>
            </Grid>
            <Grid item>
              <Typography>
                Stworzone przez{" "}
                <Link href="https://kwezal.com" target="_blank">
                  Kwezala
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}
