import { createTheme } from "@material-ui/core";

const appTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#ff9800",
    },
    secondary: {
      main: "#2196f3",
    },
    background: {
      paper: "#373737",
    },
  },
});

export default appTheme;
