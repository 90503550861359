import SynonymVariant from "./SynonymVariant";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import AppRoute from "../../config/AppRoute";

interface Props {
  readonly variant: SynonymVariant;
}

const MAX_COLUMNS = 4;

export default function SynonymVariantComponent(props: Props) {
  const variantDescription = props.variant.header;
  return (
    <Card>
      <CardContent>
        {variantDescription.length === 0 ? null : (
          <Typography className="italic">{variantDescription}</Typography>
        )}
        <Grid container spacing={3}>
          {buildGridItems(props.variant, MAX_COLUMNS)}
        </Grid>
      </CardContent>
    </Card>
  );
}

function buildGridItems(variant: SynonymVariant, maxColumnNumber: number) {
  const items = [];
  const wordsNumber = variant.words.length;
  const minRowNumber = Math.floor(wordsNumber / maxColumnNumber);
  const lastFullHeightColumnIndex =
    wordsNumber - minRowNumber * maxColumnNumber - 1;
  let wordIndex = 0;
  for (let i = 0; i < maxColumnNumber; ++i) {
    const rows = [];
    const rowNumber = minRowNumber + (i <= lastFullHeightColumnIndex ? 1 : 0);
    for (let j = 0; j < rowNumber; ++j) {
      let emojis = "";
      const note = variant.notes[wordIndex];
      if (note !== "") {
        emojis = getNoteEmojis(note);
      }
      const word = variant.words[wordIndex++];

      rows.push(
        <div key={`${i},${j}`}>
          <Typography
            variant="h6"
            color="textPrimary"
            component={RouterLink}
            to={`${AppRoute.synonym}/${word}`}
            className="word-link"
          >
            {emojis}
            {word}
          </Typography>
        </div>
      );
    }
    items.push(
      <Grid key={i} item xs>
        {rows}
      </Grid>
    );
  }
  return items;
}

function getNoteEmojis(note: string) {
  let emojis = "";
  if (note.indexOf("†") !== -1) {
    emojis += "💀";
  }
  if (note.indexOf("poet") !== -1) {
    emojis += "📝";
  }
  if (note.indexOf("posp") !== -1) {
    emojis += "🧑‍🌾";
  }
  if (note.indexOf("wulg") !== -1) {
    emojis += "🤬";
  }

  return emojis + " ";
}
