import {
  AppBar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { Link as RouterLink } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import Dimension from "../res/Dimension";
import AppRoute from "../../config/AppRoute";
import HideOnScroll from "./HideOnScroll";

type NavItemData = {
  readonly name: string;
  readonly path: string;
};

export default function MainAppBar() {
  const navItems: NavItemData[] = [
    { name: "Wyszukiwarka synonimów", path: AppRoute.home },
    { name: "Lista haseł", path: AppRoute.dictionaryEntries },
    { name: "O słowniku", path: AppRoute.aboutDictionary },
  ];

  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);

  const isMenuOpen = menuAnchor !== null;

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <HideOnScroll>
      <AppBar>
        <Box display={"flex"} justifyContent={"center"}>
          <Box
            id="toolbar"
            component={Toolbar}
            maxWidth={Dimension.CONTENT_CONTAINER_MAX_WIDTH}
            flex={1}
          >
            <Box mr={1}>
              <MenuBookIcon color="action" fontSize="large" />
            </Box>
            <Typography
              variant="h6"
              component="h1"
              color="textPrimary"
              className="bold"
            >
              Kwezal Synonimy
            </Typography>
            <div className="flex-grow" />
            <Box display={{ xs: "none", md: "block" }}>
              {navItems.map((navItem, index) => (
                <Typography
                  key={index}
                  variant="h6"
                  color="textPrimary"
                  component={RouterLink}
                  to={navItem.path}
                  className={"nav-link"}
                >
                  {navItem.name}
                </Typography>
              ))}
            </Box>
            <Box display={{ xs: "block", md: "none" }}>
              <IconButton edge="end" onClick={handleMenuOpen}>
                <MenuIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Menu
          anchorEl={menuAnchor}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          id={"nav"}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          {navItems.map((navItem, index) => (
            <MenuItem key={index} component={RouterLink} to={navItem.path}>
              {navItem.name}
            </MenuItem>
          ))}
        </Menu>
      </AppBar>
    </HideOnScroll>
  );
}
