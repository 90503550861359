import { Typography } from "@material-ui/core";

export default function NotFoundPage() {
  return (
    <>
      <Typography variant="h3" component="h1" color="error">
        404 Nie znaleziono
      </Typography>
      <Typography variant="h5" component="h2">
        Niestety, strona nie została namierzona, odnaleziona, odszukana,
        wykryta, wyniuchana, wyłowiona, wyszperana, wytrzaśnięta ani zwęszona.
      </Typography>
    </>
  );
}
