import { RouteComponentProps, withRouter } from "react-router";
import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import AppRoute from "../../config/AppRoute";
import "./DictionaryEntriesComponent.css";
import DictionaryPage from "./DictionaryPage";
import DictionaryPagination from "./DictionaryPagination";

const LETTERS = [
  "a",
  "b",
  "c",
  "ć",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "ł",
  "m",
  "n",
  "o",
  "ó",
  "p",
  "q",
  "r",
  "s",
  "ś",
  "t",
  "u",
  "v",
  "w",
  "y",
  "z",
  "ź",
  "ż",
];

interface PageInfo {
  readonly pageSize: number;
}

interface FetchResult {
  readonly letter: string;
  readonly pagesInfo: PageInfo;
}

interface PropsParams {
  readonly letter: string;
  readonly page?: string;
}

interface Props extends RouteComponentProps<PropsParams> {}

function DictionaryEntriesComponent(props: Props) {
  const letter = props.match.params.letter;
  const pageNumber = parseInt(props.match.params.page ?? "1");
  const page = isNaN(pageNumber) ? 1 : pageNumber;

  const [fetchResult, setFetchResult] = useState<FetchResult | null>(null);

  const isFetchResultReady = () =>
    fetchResult !== null && fetchResult.letter === letter;

  useEffect(() => {
    if (!isFetchResultReady()) {
      fetchPageInfo(letter)
        .then((result) => setFetchResult(result))
        .catch(() => setFetchResult(null));
    }
  });

  return (
    <>
      <Typography variant="h5" component="h1">
        Alfabetyczna lista haseł
      </Typography>
      <Box mt={1} mb={2}>
        <Grid container>
          {LETTERS.map((navLetter, index) => (
            <Grid key={index} item xs>
              <Typography
                variant="h6"
                color="textPrimary"
                component={RouterLink}
                to={`${AppRoute.dictionaryEntries}/${navLetter}`}
                className={`letter-link${
                  navLetter === letter ? " active" : ""
                }`}
              >
                {navLetter.toUpperCase()}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
      <DictionaryPage letter={letter} page={page} />
      {fetchResult === null ? null : (
        <DictionaryPagination
          letter={letter}
          page={page}
          pageSize={fetchResult.pagesInfo.pageSize}
        />
      )}
    </>
  );
}

export default withRouter(DictionaryEntriesComponent);

async function fetchPageInfo(letter: string): Promise<FetchResult> {
  const response = await fetch(`/res/synonyms/pages/${letter}/info.json`);
  const json = await response.json();
  return { letter: letter, pagesInfo: json };
}