import { Redirect, Route, Router, Switch } from "react-router-dom";
import React from "react";
import { Box, Typography } from "@material-ui/core";
import SynonymPage from "../synonym/SynonymPage";
import "./MainPage.css";
import WordSearchBar from "./WordSearchBar";
import AboutDictionaryComponent from "../about/AboutDictionaryComponent";
import DictionaryEntriesComponent from "../entries/DictionaryEntriesComponent";
import AppRoute from "../../config/AppRoute";
import MainAppBar from "./MainAppBar";
import Dimension from "../res/Dimension";
import appHistory from "../../navigation/AppHistory";
import MainFooter from "./MainFooter";

export default function MainPage() {
  return (
    <>
      <MainAppBar />
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Box mt={8} id="content-container" flexGrow="1">
          <Box m={2} maxWidth={Dimension.CONTENT_CONTAINER_MAX_WIDTH} flex={1}>
            <Router history={appHistory}>
              <Switch>
                <Redirect exact from={AppRoute.synonym} to="/" />
                <Route exact path="/">
                  <Box mb={2}>
                    <Typography variant="h5" component="h1">
                      Wyszukiwarka synonimów
                    </Typography>
                  </Box>
                  <WordSearchBar />
                </Route>
                <Route
                  path={`${AppRoute.synonym}/:query`}
                  component={SynonymPage}
                />
                <Route
                  path={AppRoute.aboutDictionary}
                  component={AboutDictionaryComponent}
                />
                <Route
                  path={`${AppRoute.dictionaryEntries}/:letter/:page?`}
                  component={DictionaryEntriesComponent}
                />
                <Redirect
                  exact
                  from={`${AppRoute.dictionaryEntries}/`}
                  to={`${AppRoute.dictionaryEntries}/a`}
                />
              </Switch>
            </Router>
          </Box>
        </Box>
        <MainFooter />
      </Box>
    </>
  );
}
